
import ApiClient from '../methods/api/apiClient'
export const Subscription = () => async (dispatch) => {
    if (!localStorage.getItem('token')) {

    } else {
        await ApiClient.get("api/my/plan", {}, '', '', { apiCall: true }).then((res) => {
            if (res.success) {
                let ActivePlanData = { ...res.data, planFeatures: res?.data?.planId?.planFeatures?.filter((itemp) => itemp?.included) };
                let data = ActivePlanData?.planAddons?.map((item) => item?.slug || item?.name) || [];
                ActivePlanData?.planFeatures?.map((item) => {
                    let ourData = item?.slug || item?.name;
                    data?.push(ourData)
                });
                return dispatch({ type: 'Subscription', data: data })
            }
        })
    }
}; 