import React from "react";
import { useSelector } from "react-redux";
import Chart from "../../../components/Chart";

const HorizontalLineGraph = ({ fbAdInsightsByRegion = [] }) => {
  const user = useSelector((state) => state.user);

  // Create value array from the incoming data
  const value = fbAdInsightsByRegion.map((item) => ({
    name: item.region, // Use the region name
    value: Number(item.clicks) || 0, // Use clicks as the value, ensuring it's a number
    impressions: Number(item.impressions) || 0,
    cpc: Number(item.cpc) || 0,
    cpm: Number(item.cpm) || 0,
    spend: Number(item.spend) || 0,
    ctr: Number(item.ctr) || 0,
  }));

  let updatedOption = {
    tooltip: {
      trigger: "item",
      formatter: (params) => {
        if (!params || !params.length || !params[0].data) {
          return "No data available"; // Handle the case where there is no data
        }

        const data = params[0].data; // Access the data safely
        return `
          <b>Region: ${data.name}</b><br/>
          Clicks: ${data.value}<br/>
          Impressions: ${data.impressions}<br/>
          CPC: $${data.cpc.toFixed(2)}<br/>
          CPM: $${data.cpm.toFixed(2)}<br/>
          Spend: $${data.spend.toFixed(2)}<br/>
          CTR: ${data.ctr.toFixed(2)}%
        `;
      },
    },
    xAxis: {
      type: "value",
      axisLabel: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      type: "category",
      data: value.map((v) => v.name),
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        fontSize: 14,
        fontWeight: 500,
        color: "#3C3E49",
        margin: 20,
      },
    },
    series: [
      {
        name: "Country Breakdown",
        type: "bar",
        label: {
          show: true,
          position: "insideLeft",
          formatter: "{c}",
          padding: 10,
          fontSize: 14,
          fontWeight: 500,
        },
        data: value.map((v) => ({
          name: v.name,
          value: v.value,
          impressions: v.impressions,
          cpc: v.cpc,
          cpm: v.cpm,
          spend: v.spend,
          ctr: v.ctr,
        })),
        itemStyle: {
          borderRadius: [0, 4, 4, 0],
          color: function (params) {
            const colors = [
              "#FFE28A",
              "#C4E3D8",
              "#DBA2F9",
              "#8986E7",
              "#FCBE72",
            ];
            return colors[params.dataIndex % colors.length]; // Ensure colors cycle correctly
          },
        },
        barWidth: 30,
      },
    ],
    grid: {
      left: "14%",
      right: "10%",
      top: "5%",
      bottom: "5%",
    },
  };

  return (
    <div className="px-[20px] pb-[20px]">
      <Chart options={updatedOption} />
    </div>
  );
};

export default HorizontalLineGraph;