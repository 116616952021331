import { useState, useEffect, useRef } from "react";
import Layout from "../../components/global/layout";
import { HiOutlineSearch } from "react-icons/hi";
import DateRangePicker from "../../components/common/DateRangePicker";
import { AiOutlineDownload } from "react-icons/ai";
import Badge from "../../components/Badge";
import LineCurveGraph from "./GraphCharts/LineCurveGraph";
import { IoIosTrendingUp } from "react-icons/io";
import SelectDropdown from "../../components/common/SelectDropdown";
import datepipeModel from "../../models/datepipemodel";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";

const GoogleAds = () => {
  // TODO: Need to make it dynamic
  const accessToken = "ya29.a0AcM612wjmlAt4hPEEJeVGWMDViRobhw_3OT1f-jj302S9Guvd71nPJzYzcdeeWsodgnaxTVQsZQIBCYxU3DijdefoBvIUlMA3CLTomnBja4P39TgtPLBXwldCs5i7rtL4W9CFIujO3XLmapBRMiPXsP-mYFrg53wcBZAHW-HaCgYKAUcSARASFQHGX2MiUsz_svBwj_y_GzWbBcXuaw0175";
  const [adAccountsOptions, setAdAccountsOptions] = useState([]);
  const [adAccountId, setAdAccountId] = useState(null);
  const [googleAdsAccounts, setGoogleAdsAccounts] = useState({});
  const [googleAdAccountInfo, setGoogleAdAccountInfo] = useState({});
  const [googleAdAccountInsights, setGoogleAdAccountInsights] = useState({});
  const [googleAdCampaigns, setGoogleAdCampaigns] = useState([]);
  const [googleAdGroups, setGoogleAdGroups] = useState([]);

  const [adAccountYear, setAdAccountYear] = useState("current_year");

  const years = [
    { id: "current_year", name: "This Year" },
    { id: "last_year", name: "Last Year" },
  ];

  const [filters, setFilter] = useState({
    page: 1,
    count: 50,
    sortBy: "dateCreated desc",
    search: "",
    posId: "",
    status: "",
    productCode: "",
    category: [],
    travelStartDate: "",
    travelEndDate: "",
    reseller: "",
    third_party_delete: "",
    startDate: datepipeModel.datetostring(new Date()),
    endDate: datepipeModel.datetostring(new Date()),
    compare: false,
    compareStart: "",
    compareEnd: "",
  });
  const onRangeChange = (e) => {
    let f = {
      startDate: datepipeModel.datetostring(e.startDate),
      endDate: datepipeModel.datetostring(e.endDate),
      compare: e.compare,
      compareStart: e.compareStart,
      compareEnd: e.compareEnd,
    };

    if (
      datepipeModel.datetostring(e.startDate) ===
      datepipeModel.datetostring(e.endDate)
    ) {
      setFilter({ ...filters, ...f });
    } else {
      setFilter({ ...filters, ...f });
      dataFilter({ ...f });
    }
  };

  const resetGoogleAdsData = () => {
    setAdAccountYear("current_year");
    setGoogleAdAccountInfo({});
    setGoogleAdAccountInsights({});
  }
  const getGoogleAdsAccounts = async () => {
    try {
      loader(true);
      const res = await ApiClient.getAll({
        url: 'api/google/customer-id',
        params: { access_token: accessToken },
      });
      if (res.success) {
        setGoogleAdsAccounts(res.data);
        const adAccountsData = res.data.resourceNames.map(resource => {
          const id = resource.split('/')[1];
          return { id: id, name: id };
        });
        setAdAccountsOptions(adAccountsData);
        if (adAccountsData.length > 0)
          setAdAccountId(adAccountsData[0].id);
      }
    } catch (error) {
      loader(false);
      console.error('Error fetching Google Ads Accounts:', error);
    }
  };

  const getGoogleAdAcountInfo = async () => {
    try {
      const res = await ApiClient.getAll({
        url: 'api/google/ad-account',
        params: {
          access_token: accessToken,
          customer_id: adAccountId,
        },
      });
      if (res.success) {
        setGoogleAdAccountInfo(res.data[0].results[0].customer);
      } else {
        loader(false);
      }
    } catch (error) {
      loader(false);
      console.error('Error fetching Google Ads Account Info:', error);
    }
  };

  const getGoogleAdAccountInsights = async () => {
    try {
      const res = await ApiClient.getAll({
        url: 'api/google/ad-account/insights',
        params: {
          access_token: accessToken,
          customer_id: adAccountId,
          start_date: filters.startDate,
          end_date: filters.endDate,
        },
      });
      if (res.success) {
        setGoogleAdAccountInsights(res.data[0].results[0]);
      } else {
        loader(false);
      }
    } catch (error) {
      loader(false);
      console.error('Error fetching Google Ads Account Insights:', error);
    }
  };

  const getGoogleAdCampaigns = async () => {
    try {
      const res = await ApiClient.getAll({
        url: 'api/google/campaigns',
        params: {
          access_token: accessToken,
          customer_id: adAccountId,
          start_date: filters.startDate,
          end_date: filters.endDate,
        },
      });
      if (res.success) {
        setGoogleAdCampaigns(res.data[0].results);
        // Loop through each campaign and await insights
        for (const result of res.data[0].results) {
          getGoogleAdCampaignsInsights(result.campaign.id);
        }
      } else {
        loader(false);
      }
    } catch (error) {
      loader(false);
      console.error('Error fetching Google Ad Campaigns:', error);
    }
  };

  const getGoogleAdCampaignsInsights = async (campaignId) => {
    try {
      const res = await ApiClient.getAll({
        url: 'api/google/campaigns/insights',
        params: {
          access_token: accessToken,
          customer_id: adAccountId,
          campaign_id: campaignId,
          start_date: filters.startDate,
          end_date: filters.endDate,
        },
      });
      if (res.success) {
        setGoogleAdCampaignInsights((prevInsights) => ({
          ...prevInsights,
          [campaignId]: res.data[0].results[0],
        }));
      } else {
        loader(false);
      }
    } catch (error) {
      loader(false);
      console.error('Error fetching Google Ad Campaigns Insights:', error);
    }
  };

  const getGoogleAdGroups = async () => {
    try {
      const res = await ApiClient.getAll({
        url: 'api/google/adgroups',
        params: {
          access_token: accessToken,
          customer_id: adAccountId,
        },
      });
      if (res.success) {
        setGoogleAdGroups(res.data[0].results);
      } else {
        loader(false);
      }
    } catch (error) {
      loader(false);
      console.error('Error fetching Google Ad Groups:', error);
    }
  };

  useEffect(async () => {
    await getGoogleAdsAccounts();
  }, []);

  useEffect(() => {
    if (adAccountId) {
      resetGoogleAdsData();
      getGoogleAdAcountInfo();
      getGoogleAdAccountInsights();
      getGoogleAdCampaigns();
      getGoogleAdGroups();
    }
  }, [adAccountId, filters]);

  return (
    <div>
      <Layout>
        <div className="flex items-center justify-between mb-8">
          <div className="flex flex-col gap-1">
            <h4 className="text-2xl font-semibold text-[#3C3E49]">
              Google Ads
            </h4>
            <p className="text-sm font-normal text-[#75757A]">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.
            </p>
          </div>
          <div className="flex gap-2">
            <SelectDropdown
              displayValue="name"
              placeholder="Select Google Ad Account"
              showPlaceHolder={false}
              options={adAccountsOptions}
              intialValue={adAccountId}
              result={(e) => {
                setAdAccountId(e.value);
              }}
            />
            <DateRangePicker
              disabled={filters.yearType === "calendar"}
              title={
                filters.yearType === "calendar"
                  ? ""
                  : "Change Data by Calendar Year to change dates"
              }
              value={{
                startDate: filters.startDate,
                endDate: filters.endDate,
                compare: filters.compare,
                compareStart: filters.compareStart,
                compareEnd: filters.compareEnd,
              }}
              isCompare={true}
              onChange={(e) => onRangeChange(e)}
              fullWidth={false}
            />
          </div>
        </div>

        <div className="grid grid-cols-2 xl:grid-cols-4 gap-4 mb-6">
          <div className="p-4 flex flex-col gap-8 rounded-large bg-white shadow-box border border-grey">
            <Badge label="Total Impression" />
            <div className="flex justify-between gap-4 items-center">
              <h2 className="text-typo text-2xl font-medium mb-2.5">10,000</h2>
              <img src="/assets/img/svgs/graphdown-green.svg" alt="" />
            </div>
          </div>
          <div className="p-4 flex flex-col gap-8 rounded-large bg-white shadow-box border border-grey">
            <Badge label="Total Clicks" />
            <div className="flex justify-between gap-4 items-center">
              <h2 className="text-typo text-2xl font-medium mb-2.5">25,000</h2>
              <img src="/assets/img/svgs/graphdown-green.svg" alt="" />
            </div>
          </div>
          <div className="p-4 flex flex-col gap-8 rounded-large bg-white shadow-box border border-grey">
            <Badge label="CTR" />
            <div className="flex justify-between gap-4 items-center">
              <h2 className="text-typo text-2xl font-medium mb-2.5">25%</h2>
              <img src="/assets/img/svgs/graphdown-green.svg" alt="" />
            </div>
          </div>
          <div className="p-4 flex flex-col gap-8 rounded-large bg-white shadow-box border border-grey">
            <Badge label="Conversion" />
            <div className="flex justify-between gap-4 items-center">
              <h2 className="text-typo text-2xl font-medium mb-2.5">10,000</h2>
              <img src="/assets/img/svgs/graph-down.svg" alt="" />
            </div>
          </div>
          <div className="p-4 flex flex-col gap-8 rounded-large bg-white shadow-box border border-grey">
            <Badge label="Conversion Rate" />
            <div className="flex justify-between gap-4 items-center">
              <h2 className="text-typo text-2xl font-medium mb-2.5">5%</h2>
              <img src="/assets/img/svgs/graph-down.svg" alt="" />
            </div>
          </div>
          <div className="p-4 flex flex-col gap-8 rounded-large bg-white shadow-box border border-grey">
            <Badge label="Total Cost" />
            <div className="flex justify-between gap-4 items-center">
              <h2 className="text-typo text-2xl font-medium mb-2.5">10,000</h2>
              <img src="/assets/img/svgs/graphdown-green.svg" alt="" />
            </div>
          </div>
          <div className="p-4 flex flex-col gap-8 rounded-large bg-white shadow-box border border-grey">
            <Badge label="Avg CPC" />
            <div className="flex justify-between gap-4 items-center">
              <h2 className="text-typo text-2xl font-medium mb-2.5">$4.00</h2>
              <img src="/assets/img/svgs/graphdown-green.svg" alt="" />
            </div>
          </div>
          <div className="p-4 flex flex-col gap-8 rounded-large bg-white shadow-box border border-grey">
            <Badge label="CPA" />
            <div className="flex justify-between gap-4 items-center">
              <h2 className="text-typo text-2xl font-medium mb-2.5">$20</h2>
              <img src="/assets/img/svgs/graphdown-green.svg" alt="" />
            </div>
          </div>
        </div>
        <div className="shadow-box border border-grey bg-white rounded-large w-full mb-6">
          <div className="flex justify-between gap-4 items-center p-5">
            <div className="">
              <h1 className="text-typo text-lg font-semibold">
                Account Overview
              </h1>
              <div className="flex gap-2 items-center">
                <div className="flex w-fit rounded-full gap-0.5 items-center py-0.5 px-1.5 text-xs font-medium text-[#047857]">
                  <IoIosTrendingUp className="text-[#047857]" />
                  2.11%
                </div>
                <p className="text-sm font-medium text-[#6B7280]">
                  Compare to previous month
                </p>
              </div>
            </div>
            <SelectDropdown
              displayValue="name"
              placeholder="This Month"
              options={years}
              noDefault={true}
            />
          </div>
          <LineCurveGraph fbAdInsightsYearly={[]} />
        </div>
        <div className="shadow-box border border-grey bg-white rounded-large mb-6">
          <div className="flex justify-between gap-4 px-5 pt-6 pb-4 items-center">
            <h1 className="text-typo text-lg font-bold">
              Top Performing Campaigns
            </h1>
            <div>
              <div className="flex gap-4">
                <form className="relative max-w-[250px] w-full">
                  <HiOutlineSearch className="text-[#717275] text-xl shrink-0 absolute top-2.5 left-2" />
                  <input
                    className="w-full outline-none pl-9 p-0 placeholder:text-sm text-typo text-sm placeholder:font-light rounded-large h-10 flex items-center gap-2 px-2 focus:ring-primary focus:ring-2 shadow-box border !border-grey"
                    placeholder="Search by name"
                  />
                </form>
                <button
                  type="button"
                  className="relative cursor-default text-sm rounded-large bg-[#fff] !px-4 text-left focus:outline-none h-10 flex items-center justify-center gap-2 shadow-box border !border-grey hover:bg-[#F3F2F5] transition-all disabled:bg-[#F3F2F5] disabled:cursor-not-allowed"
                >
                  <AiOutlineDownload className="text-typo text-xl" />
                  Download CSV
                </button>
              </div>
            </div>
          </div>
          <div className="scrollbar w-full overflow-auto">
            <table className="w-full">
              <thead className="">
                <tr className="w-full">
                  <th className=" text-left cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Campaign
                  </th>
                  <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Impr.
                  </th>
                  <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Clicks
                  </th>
                  <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    CTR
                  </th>
                  <th className="text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Avg.CPC
                  </th>
                  <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Conv.
                  </th>
                  <th className="cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm text-center border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Conversion Rate
                  </th>
                  <th className="cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm text-center border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Cost/ conv.
                  </th>
                  <th className="cursor-pointer text-[#82838B] border-b-0 border-x-0 font-normal text-sm text-center border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Cost
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className=" text-typo cursor-pointer px-3.5 text-sm border-l-0 border-b-0 font-normal !py-4 border border-[#EAECF0]">
                    Jewelry
                  </td>
                  <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                    18,409
                  </td>
                  <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                    1,224
                  </td>
                  <td className="text-center text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border border-[#EAECF0]">
                    6.65%
                  </td>
                  <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                    $ 3.80
                  </td>
                  <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 text-center border border-[#EAECF0]">
                    881
                  </td>
                  <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                    18.43%
                  </td>
                  <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                    5.28%
                  </td>
                  <td className=" text-typo cursor-pointer px-3.5 border-r-0 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                    $ 4,650.98
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="shadow-box border border-grey bg-white rounded-large mb-6">
          <div className="flex justify-between gap-4 px-5 pt-6 pb-4 items-center">
            <h1 className="text-typo text-lg font-bold">
              Keywords
            </h1>
            <div>
              <div className="flex gap-4">
                <form className="relative max-w-[250px] w-full">
                  <HiOutlineSearch className="text-[#717275] text-xl shrink-0 absolute top-2.5 left-2" />
                  <input
                    className="w-full outline-none pl-9 p-0 placeholder:text-sm text-typo text-sm placeholder:font-light rounded-large h-10 flex items-center gap-2 px-2 focus:ring-primary focus:ring-2 shadow-box border !border-grey"
                    placeholder="Search by name"
                  />
                </form>
                <button
                  type="button"
                  className="relative cursor-default text-sm rounded-large bg-[#fff] !px-4 text-left focus:outline-none h-10 flex items-center justify-center gap-2 shadow-box border !border-grey hover:bg-[#F3F2F5] transition-all disabled:bg-[#F3F2F5] disabled:cursor-not-allowed"
                >
                  <AiOutlineDownload className="text-typo text-xl" />
                  Download CSV
                </button>
              </div>
            </div>
          </div>
          <div className="scrollbar w-full overflow-auto">
            <table className="w-full">
              <thead className="">
                <tr className="w-full">
                  <th className=" text-left cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Campaign
                  </th>
                  <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Campaign Objective
                  </th>
                  <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Impr.
                  </th>
                  <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Clicks
                  </th>
                  <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    CTR
                  </th>
                  <th className="text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Avg.CPC
                  </th>
                  <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Conv.
                  </th>
                  <th className="cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm text-center border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Conversion Rate
                  </th>
                  <th className="cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm text-center border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Cost/ conv.
                  </th>
                  <th className="cursor-pointer text-[#82838B] border-b-0 border-x-0 font-normal text-sm text-center border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Cost
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className=" text-typo cursor-pointer px-3.5 text-sm border-l-0 border-b-0 font-normal !py-4 border border-[#EAECF0]">
                    Jewelry
                  </td>
                  <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                    performance
                  </td>
                  <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                    18,409
                  </td>
                  <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                    1,224
                  </td>
                  <td className="text-center text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border border-[#EAECF0]">
                    6.65%
                  </td>
                  <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                    $ 3.80
                  </td>
                  <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 text-center border border-[#EAECF0]">
                    881
                  </td>
                  <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                    18.43%
                  </td>
                  <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                    5.28%
                  </td>
                  <td className=" text-typo cursor-pointer px-3.5 border-r-0 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                    $ 4,650.98
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Layout>
      ;
    </div>
  );
};

export default GoogleAds;
