import React, { useState } from "react";
import Layout from "../../components/global/layout";
import datepipeModel from "../../models/datepipemodel";
import DateRangePicker from "../../components/common/DateRangePicker";
import { IoIosTrendingUp } from "react-icons/io";
import { BsPlus } from "react-icons/bs";
import { HiMiniMinus } from "react-icons/hi2";
import SelectDropdown from "../../components/common/SelectDropdown";
import LineCurveGraph from "./GraphCharts/LineCurveGraph";
import VerticalBarGraph from "./GraphCharts/VerticalBarGraph";
import AudienceVerticalBarGraph from "./GraphCharts/AudienceVerticalBarGraph";
import EngagementVerticalBar from "./GraphCharts/EngagementVerticalBar";
import CommonLoader from "../../components/common/LoaderCommon/CommonLoader";
import CountrySalesChart from "../../components/SalesChats/CountrySalesChart";
import pipeModel from "../../models/pipeModel";
import { useSelector } from "react-redux";

const InstagramAnalysis = () => {
  const options = [
    { id: "Line", name: "Line Chart" },
    { id: "Bar", name: "Bar Chart" },
  ];
  const months = [
    { id: "month", name: "January" },
    { id: "month", name: "February" },
  ];
  const [filters, setFilter] = useState({
    page: 1,
    count: 50,
    sortBy: "dateCreated desc",
    search: "",
    posId: "",
    status: "",
    productCode: "",
    category: [],
    travelStartDate: "",
    travelEndDate: "",
    reseller: "",
    third_party_delete: "",
    startDate: datepipeModel.datetostring(new Date()),
    endDate: datepipeModel.datetostring(new Date()),
    compare: false,
    compareStart: "",
    compareEnd: "",
  });
  const onRangeChange = (e) => {
    let f = {
      startDate: datepipeModel.datetostring(e.startDate),
      endDate: datepipeModel.datetostring(e.endDate),
      compare: e.compare,
      compareStart: e.compareStart,
      compareEnd: e.compareEnd,
    };

    if (
      datepipeModel.datetostring(e.startDate) ===
      datepipeModel.datetostring(e.endDate)
    ) {
      setFilter({ ...filters, ...f });
    } else {
      setFilter({ ...filters, ...f });
      dataFilter({ ...f });
    }
  };
  const user = useSelector((state) => state.user);
  const [ResellerDirectLoader, setResellerDirectLoader] = useState(false);
  const [resellerChartData, setResellerChartData] = useState();
  const [currencyChartData, setCurrencyChartData] = useState({
    paymentOptionsSales: [],
    salesByCurrency: [],
  });
  const SalesByCountry = ({ currencyChartData, id = "" }) => {
    return (
      <>
        <div
          className={
            filters.compare
              ? "grid grid-cols-1 gap-5 p-4"
              : "grid grid-cols-1 gap-5 p-4"
          }
        >
          <div className="">
            <CountrySalesChart
              value={currencyChartData?.salesByCountry || []}
              id={id}
              tooltipHtml={true}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <Layout>
      <div className="flex items-center justify-between mb-6">
        <div className="flex flex-col gap-1">
          <h4 className="text-2xl font-semibold text-[#3C3E49]">
            Instagram Analysis
          </h4>
          <p className="text-sm font-normal text-[#75757A]">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt,
            animi!
          </p>
        </div>
        <DateRangePicker
          disabled={filters.yearType === "calendar"}
          title={
            filters.yearType === "calendar"
              ? ""
              : "Change Data by Calendar Year to change dates"
          }
          value={{
            startDate: filters.startDate,
            endDate: filters.endDate,
            compare: filters.compare,
            compareStart: filters.compareStart,
            compareEnd: filters.compareEnd,
          }}
          isCompare={true}
          onChange={(e) => onRangeChange(e)}
          fullWidth={false}
        />
      </div>
      <div className="grid grid-cols-2 xl:grid-cols-3 gap-4 mb-6">
        <div className="p-4 flex flex-col rounded-large bg-white shadow-box border border-grey">
          <h5 className="text-base font-medium text-[#3C3E49] mb-5">
            New Followers
          </h5>
          <h1 className="text-2xl font-semibold text-[#111827] mb-1">4,546</h1>
          <div className="flex w-fit bg-[#047857]/10 rounded-full gap-0.5 items-center py-0.5 px-1.5 text-xs font-medium text-[#047857]">
            <BsPlus className="text-[#047857]" />
            114.3%
          </div>
        </div>
        <div className="p-4 flex flex-col rounded-large bg-white shadow-box border border-grey">
          <h5 className="text-base font-medium text-[#3C3E49] mb-5">
            Followers (lifetime)
          </h5>
          <h1 className="text-2xl font-semibold text-[#111827] mb-1">1,527</h1>
          <div className="flex w-fit bg-[#E92531]/10 rounded-full gap-0.5 items-center py-0.5 px-1.5 text-xs font-medium text-[#E92531]">
            <HiMiniMinus className="text-[#E92531]" />
            52.5%
          </div>
        </div>
        <div className="p-4 flex flex-col rounded-large bg-white shadow-box border border-grey">
          <h5 className="text-base font-medium text-[#3C3E49] mb-5">
            Impressions
          </h5>
          <h1 className="text-2xl font-semibold text-[#111827] mb-1">201</h1>
          <div className="flex w-fit bg-[#E92531]/10 rounded-full gap-0.5 items-center py-0.5 px-1.5 text-xs font-medium text-[#E92531]">
            <HiMiniMinus className="text-[#E92531]" />
            91.6%
          </div>
        </div>
        <div className="p-4 flex flex-col rounded-large bg-white shadow-box border border-grey">
          <h5 className="text-base font-medium text-[#3C3E49] mb-5">Reach</h5>
          <h1 className="text-2xl font-semibold text-[#111827] mb-1">1,890</h1>
          <div className="flex w-fit bg-[#E92531]/10 rounded-full gap-0.5 items-center py-0.5 px-1.5 text-xs font-medium text-[#E92531]">
            <HiMiniMinus className="text-[#E92531]" />
            56.2%
          </div>
        </div>
        <div className="p-4 flex flex-col rounded-large bg-white shadow-box border border-grey">
          <h5 className="text-base font-medium text-[#3C3E49] mb-5">
            Website clicks
          </h5>
          <h1 className="text-2xl font-semibold text-[#111827] mb-1">2,746</h1>
          <div className="flex w-fit bg-[#E92531]/10 rounded-full gap-0.5 items-center py-0.5 px-1.5 text-xs font-medium text-[#E92531]">
            <HiMiniMinus className="text-[#E92531]" />
            33.4%
          </div>
        </div>
        <div className="p-4 flex flex-col rounded-large bg-white shadow-box border border-grey">
          <h5 className="text-base font-medium text-[#3C3E49] mb-5">
            Profile views
          </h5>
          <h1 className="text-2xl font-semibold text-[#111827] mb-1">686</h1>
          <div className="flex w-fit bg-[#047857]/10 rounded-full gap-0.5 items-center py-0.5 px-1.5 text-xs font-medium text-[#047857]">
            <BsPlus className="text-[#047857]" />
            326.1%
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-1 mb-6">
        <h4 className="text-2xl font-semibold text-[#3C3E49]">Summary</h4>
        <p className="text-sm font-normal text-[#75757A]">
          Here you can provide an explanation of the results for the reporting
          period. Usually an overview is given of actions, tasks, goals and
          results. You can also explain what next steps can be and what is
          expected of all the stakeholders.
        </p>
      </div>
      <div className="shadow-box border border-grey bg-white rounded-large w-full mb-6">
        <div className="flex justify-between gap-4 items-center p-5">
          <h1 className="text-typo text-lg font-semibold">Reach by Day</h1>
          <div className="flex gap-2">
            <SelectDropdown
              displayValue="name"
              placeholder="Select Chart"
              options={options}
              noDefault={true}
            />
            <SelectDropdown
              displayValue="name"
              placeholder="This Month"
              options={months}
              noDefault={true}
            />
          </div>
        </div>
        <LineCurveGraph />
      </div>
      <div className="shadow-box border border-grey bg-white rounded-large w-full mb-6">
        <div className="flex justify-between gap-4 items-center p-5">
          <h1 className="text-typo text-lg font-semibold">
            Engagement and Video views by Day of the week
          </h1>
          <div className="flex gap-2">
            <SelectDropdown
              displayValue="name"
              placeholder="Select Chart"
              options={options}
              noDefault={true}
            />
            <SelectDropdown
              displayValue="name"
              placeholder="This Month"
              options={months}
              noDefault={true}
            />
          </div>
        </div>
        <EngagementVerticalBar />
      </div>
      <div className="shadow-box border border-grey bg-white rounded-large w-full mb-6">
        <div className="flex justify-between gap-4 items-center p-5">
          <h1 className="text-typo text-lg font-semibold">
            Audience by Age and Gender
          </h1>
          <div className="flex gap-2">
            <SelectDropdown
              displayValue="name"
              placeholder="Select Chart"
              options={options}
              noDefault={true}
            />
            <SelectDropdown
              displayValue="name"
              placeholder="This Month"
              options={months}
              noDefault={true}
            />
          </div>
        </div>
        <AudienceVerticalBarGraph />
      </div>
      <div className="shadow-box border border-grey bg-white rounded-large w-full mb-6">
        <div className="flex justify-between gap-4 items-center p-5">
          <h1 className="text-typo text-lg font-semibold">Audience by City</h1>
        </div>
        {ResellerDirectLoader ? (
          <CommonLoader />
        ) : (
          <SalesByCountry
            resellerChartData={resellerChartData}
            currencyChartData={currencyChartData}
            id="normal"
            title={`${filters.compare ? `${compareTitle()}` : ""}`}
          />
        )}
        {filters.compare ? (
          <SalesByCountry
            resellerChartData={resellerChartDataC}
            currencyChartData={currencyChartDataC}
            id="compare"
            title={`${filters.compare}`}
          />
        ) : (
          <></>
        )}
      </div>
      <div className="shadow-box border border-grey bg-white rounded-large mb-6">
        <div className="flex justify-between gap-4 p-5 items-center">
          <h1 className="text-typo text-lg font-semibold">
            Top 5 Post Performance
          </h1>
          <SelectDropdown
            displayValue="name"
            placeholder="This Month"
            options={months}
            noDefault={true}
          />
        </div>
        <table className="w-full">
          <thead className="">
            <tr className="w-full">
              <th className=" text-left cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                Post
              </th>
              <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                Impr.
              </th>
              <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                Reach
              </th>
              <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                Engagement
              </th>
              <th className="text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                Video views
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border border-[#EAECF0]">
                wallaby
              </td>
              <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                3,107
              </td>
              <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                4,914
              </td>
              <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border border-[#EAECF0] text-center">
                4,377{" "}
              </td>
              <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                5,727
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="flex flex-col gap-1 mb-6">
        <h4 className="text-2xl font-semibold text-[#3C3E49]">
          Story Performance
        </h4>
        <p className="text-sm font-normal text-[#75757A]">
          Here you can provide an explanation of the results for the reporting
          period. Usually an overview is given of actions, tasks, goals and
          results. You can also explain what next steps can be and what is
          expected of all the stakeholders.
        </p>
      </div>
      <div className="grid grid-cols-2 xl:grid-cols-3 gap-4 mb-6">
        <div className="p-4 flex flex-col rounded-large bg-white shadow-box border border-grey">
          <h5 className="text-base font-medium text-[#3C3E49] mb-5">
            Impressions
          </h5>
          <h1 className="text-2xl font-semibold text-[#111827] mb-1">201</h1>
        </div>
        <div className="p-4 flex flex-col rounded-large bg-white shadow-box border border-grey">
          <h5 className="text-base font-medium text-[#3C3E49] mb-5">Reach</h5>
          <h1 className="text-2xl font-semibold text-[#111827] mb-1">1,890</h1>
        </div>
        <div className="p-4 flex flex-col rounded-large bg-white shadow-box border border-grey">
          <h5 className="text-base font-medium text-[#3C3E49] mb-5">Replies</h5>
          <h1 className="text-2xl font-semibold text-[#111827] mb-1">1,480</h1>
        </div>
      </div>
      <div className="shadow-box border border-grey bg-white rounded-large mb-6">
        <div className="flex justify-between gap-4 p-5 items-center">
          <h1 className="text-typo text-lg font-semibold">Story performance</h1>
        </div>
        <table className="w-full">
          <thead className="">
            <tr className="w-full">
              <th className=" text-left cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                Post
              </th>
              <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                Reach
              </th>
              <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                Impr.
              </th>
              <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                Replies
              </th>
              <th className="text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                Taps forward
              </th>
              <th className="text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                Taps back
              </th>
              <th className="text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                Exits
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border border-[#EAECF0]">
                cloak
              </td>
              <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                4,899
              </td>
              <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                4,996
              </td>
              <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border border-[#EAECF0] text-center">
                4,080
              </td>
              <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                201
              </td>
              <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                2,270
              </td>
              <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                1,788
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Layout>
  );
};

export default InstagramAnalysis;
